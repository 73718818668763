// import smoothscroll from 'smoothscroll-polyfill';
// smoothscroll.polyfill();

class App {
    constructor() {
        this.datas = [];
        this.slide_time = 3000;
        this.transition_time = 1000;
    }

    gnav() {
        $('.gsnav').on('click', function () {
            $('.menu-trigger', this).toggleClass('active');
            $('.gNav').toggleClass('on');
            return false;
        });
        $('.nav-close a').on('click', function () {
            $('.menu-trigger').removeClass('active');
            $('.gNav').removeClass('on');
            return false;
        });
    }

    headerFix() {
        let $header = $('.home .header');
        if ($header.length > 0) {
            let fixedClass = 'fixed';
            let $win = $(window);
            let $main = $('.main');
            let headerHeight = $header.outerHeight();
            let headerPos = $header.offset().top;
            $win.on('load scroll', function () {
                let value = $(this).scrollTop();
                if (value > headerPos) {
                    $header.addClass(fixedClass);
                    $main.css('padding-top', headerHeight);
                } else {
                    $header.removeClass(fixedClass);
                    $main.css('padding-top', '0');
                }
            });
        }
    }

    toppage_infinite() {
        let $home_torch_slide = $('.home-torch-slide');
        let ul1 = $home_torch_slide.find('ul').clone();
        let ul2 = $home_torch_slide.find('ul').clone();
        $home_torch_slide.append(ul1);
        $home_torch_slide.append(ul2);
    }

    pageScroll() {
        $('.page-scroll').on('click', function () {
            const speed = 500;
            const href = $(this).attr("href");
            const target = href == "top" || href == "#" || href == "" ? 'html' : href;
            let position = $(target).offset().top;
            if (target != 'html') {
                position = $(target).offset().top - ($('.header').height() + 30);
                console.log(position);
            }
            $('html, body').animate({ scrollTop: position }, speed, 'swing');
            return false;
        });
    }

    init() {
        this.gnav();
        this.toppage_infinite();
        this.headerFix();
    }
}

let app = new App();

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.addEventListener('load', () => {

});
